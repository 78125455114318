<template>
  <div class="case-suite">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/project'}">项目管理</el-breadcrumb-item>
      <el-breadcrumb-item>
        测试套件
        <el-tooltip class="item" effect="dark" content="点击表格行内容跳转" placement="right">
          <i class="el-icon-info"></i>
        </el-tooltip>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <el-card class="box-card">
      <div class="suite-header" slot="header">
        <div>
          <el-button round icon="el-icon-d-arrow-left" @click="goHome">返回</el-button>
          <el-button type="warning" icon="el-icon-plus" @click="startRunSuite">执行套件</el-button>
          <el-button type="warning" icon="el-icon-caret-right" @click="handleAdd">新增套件</el-button>
          <el-upload
            class="upload-suite"
            action="https://jsonplaceholder.typicode.com/posts/"
            :http-request="handleDelUpload"
            :show-file-list="false">
            <el-button type="warning" icon="el-icon-upload2">导入套件</el-button>
          </el-upload>
          <el-button type="warning" icon="el-icon-download" @click="handleExport">导出套件</el-button>
        </div>
        <el-form :inline="true" :model="suiteForm" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="suiteForm.suiteName" placeholder="输入测试套件名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleSearch">查询</el-button>
          </el-form-item>
        </el-form>
      </div>

      <!-- 显示测试套件内容 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        ref="multipleTable"
        @row-click="handleSuite"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="id" label="套件ID" width="180"></el-table-column>
        <el-table-column prop="suite_name" label="套件名称" width="180"></el-table-column>
        <el-table-column prop="description" label="套件描述" width="200"></el-table-column>
        <el-table-column prop="isDeleted" label="套件状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" active-color="#13ce66" inactive-color="#ff4949" active-value="1" inactive-value="0" disabled></el-switch>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button round type="warning" @click.stop="handleEdit(scope.row)">编辑</el-button>
            <el-button round type="danger" @click.stop="handleDel(scope.row)">删除</el-button>
            <el-button round type="info" @click.stop="handleDis(scope.row)">{{ scope.row.status === '1' ? '禁用' : '启用' }}</el-button>
          </template>
        </el-table-column>
      </el-table>

    </el-card>

    <!-- 测试套件分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageInfo.currentPage"
      :page-sizes="[5, 10, 30, 50]"
      :page-size="pageInfo.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="this.pageInfo.total_count">
    </el-pagination>

    <!-- 添加套件信息dialog弹窗 -->
    <el-dialog title="套件信息" :visible.sync="dialogFormVisibleAdd">
      <el-form :model="dialogSuiteForm" ref="dialogSuiteFormRef" :rules="suiteFormRules">
        <el-form-item label="套件名称" prop="suite_name">
          <el-input v-model="dialogSuiteForm.suite_name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="套件描述" prop="description">
          <el-input v-model="dialogSuiteForm.description" autocomplete="off" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="所属项目" prop="project_id">
          <el-input v-model="dialogSuiteForm.project_id" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="operation" prop="operation">
          <el-input v-model="dialogSuiteForm.operation" autocomplete="off" disabled></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisibleAdd = false">取 消</el-button>
        <el-button v-if="isAdd" type="primary" @click="processAdd">确定新增</el-button>
        <el-button v-if="!isAdd" type="primary" @click="processEdit">确定编辑</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { QuerySuiteList, DelSuite, AddSuite, EditSuite, DisSuite, ImportSuite, ExportSuite, SearchSuite, RunSuite } from '@/services/suite.js'
export default {
  name: 'CaseSuite',
  data () {
    return {
      isAdd: true,
      currentId: null,
      suiteForm: {
        suiteName: ''
      },
      tableData: [],
      multipleSelection: [],
      multipleSelections: [],
      dialogFormVisibleAdd: false,
      pageInfo: {
        size: 10,
        currentPage: 1,
        total_count: null
      },
      dialogSuiteForm: {
        suite_name: '',
        description: '',
        project_id: this.$store.state.projectId || null,
        operation: 'add'
      },
      suiteFormRules: {
        suite_name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
          { min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur' }
        ],
        description: [
          { required: true, message: '请输入项目描述', trigger: 'blur' },
          { min: 3, max: 100, message: '长度在 3 到 100 个字符', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    this.loadSuiteList()
  },
  methods: {
    // 操作当前选中的套件
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log(this.multipleSelection, '<--选中的数据')
      // 清空之前的 multipleSelections 值，取消选择后重新更新数据
      this.multipleSelections = []
      // 此时 取消/选择 套件的时候，让对应的 ID 信息更新在指定的数据中
      this.multipleSelection.map((item) => {
        // 每次存储数据的时候需要判断当前的 ID 是否已经存在
        if (!this.multipleSelections.includes(item.id)) {
          this.multipleSelections.push(item.id)
        }
      })
      console.log(this.multipleSelections, '<--要穿入的测试套件ID列表')
    },
    // 测试套件信息 分页处理
    handleSizeChange (val) {
      this.pageInfo.size = val
      this.loadSuiteList()
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      this.pageInfo.currentPage = val
      this.loadSuiteList()
      console.log(`当前页: ${val}`)
    },
    // 查询测试套件列表
    async loadSuiteList () {
      const queryObj = {
        project_id: this.$store.state.projectId,
        page: this.pageInfo.currentPage,
        size: this.pageInfo.size,
        query_type: 'query_list'
      }
      console.log(queryObj, '<--套件列表请求参数')
      const { data: res } = await QuerySuiteList(queryObj)
      console.log(res, '<--测试套件列表信息')
      if (res.status === 1) {
        this.tableData = res.data
        this.pageInfo.total_count = res.total_count
      }
    },
    // 测试套件查询
    async handleSearch () {
      const SearchObj = {
        query_type: 'search_suite',
        suite_name: this.suiteForm.suiteName,
        project_id: this.$store.state.projectId
      }
      const { data: res } = await SearchSuite(SearchObj)
      console.log(res, '<--查询的测试套件结果')
      if (res.status === 1) {
        this.tableData = res.data
      }
    },
    goHome () {
      this.$router.go(-1)
    },
    async handleDel (rowData) {
      this.$confirm('此操作将永久删除测试套件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const DelObj = {
          id: rowData.id,
          project_id: this.$store.state.projectId
        }
        const { data: res } = await DelSuite(DelObj)
        console.log(res, '<--删除对应的测试套件')
        if (res.status === 1) {
          this.$message.success('删除测试套件成功')
          this.loadSuiteList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 添加测试套件 dialog 弹窗
    handleAdd () {
      this.isAdd = true
      this.dialogSuiteForm.project_id = this.$store.state.projectId
      this.dialogFormVisibleAdd = true
    },
    // 添加测试套件动作
    async processAdd () {
      try {
        await this.$refs.dialogSuiteFormRef.validate()
        const addOperation = {
          project_id: this.$store.state.projectId,
          operation: 'add',
          suite_name: this.dialogSuiteForm.suite_name,
          description: this.dialogSuiteForm.description
        }
        const { data: res } = await AddSuite(addOperation)
        if (res.status === 1) {
          this.dialogFormVisibleAdd = false
          this.$refs.dialogSuiteFormRef.resetFields()
          this.$message.success('新增测试成功')
          this.loadSuiteList()
        }
      } catch (error) {
        this.$message.warning('信息校验未通过')
      }
    },
    async startRunSuite () {
      const suiteObj = {
        project_id: this.$store.state.projectId,
        project_name: '写四太舞财税',
        env_id: '1',
        suiteid_list: this.multipleSelections,
        executionMode: '手动执行测试套件'
      }
      if (this.multipleSelections.length === 1) {
        const { data: res } = await RunSuite(suiteObj)
        console.log(res, '<-- 运行测试套件结果')
        if (res.status === 1) {
          this.$message.success('运行测试套件成功，请在测试报告中查看运行结果')
          this.loadSuiteList()
        } else this.$message.error(res.message)
      } else if (this.multipleSelections.length > 1) {
        const { data: res } = await RunSuite(suiteObj)
        console.log(res, '<-- 运行测试套件结果')
        if (res.status === 1) {
          this.$message.success('运行测试套件成功，请在测试报告中查看运行结果')
          this.loadSuiteList()
        } else this.$message.error(res.message)
      } else {
        this.$message.warning('请选择要执行的套件')
      }
    },
    handleEdit (rowData) {
      this.isAdd = false
      this.dialogFormVisibleAdd = true
      this.currentId = rowData.id
      this.dialogSuiteForm.suite_name = rowData.suite_name
      this.dialogSuiteForm.description = rowData.description
    },
    // 编辑测试套件
    async processEdit () {
      try {
        const EditObj = {
          id: this.currentId,
          project_id: this.$store.state.projectId,
          suite_name: this.dialogSuiteForm.suite_name,
          description: this.dialogSuiteForm.description
        }
        const { data: res } = await EditSuite(EditObj)
        console.log(res, '<--编辑测试套件返回信息')
        if (res.status === 1) {
          this.dialogFormVisibleAdd = false
          this.dialogSuiteForm.suite_name = ''
          this.dialogSuiteForm.description = ''
          this.$message.success('编辑测试套件成功')
          this.loadSuiteList()
        }
      } catch (error) {
        this.$message.warning('校验信息失败')
      }
    },
    // 开启/禁用测试套件
    async handleDis (rowData) {
      const DisObj = {
        project_id: this.$store.state.projectId,
        id: rowData.id
      }
      const { data: res } = await DisSuite(DisObj)
      if (res.status === 1) {
        this.$message.success(res.data.status === '0' ? '禁用成功' : '启用成功')
        this.loadSuiteList()
      }
    },
    async handleDelUpload (options) {
      console.log(options, '<--没有')
      // 使用 form/data 类型 请求数据 并且组装请求数据
      const fd = new FormData()
      fd.append('file', options.file)
      fd.append('project_id', this.$store.state.projectId)
      fd.append('operation', 'import')

      // 发送请求
      const { data: res } = await ImportSuite(fd)
      console.log(res, '<--上传后的返回数据')
      if (res.status === 1) {
        this.$message.success('导入测试套件成功')
        this.loadSuiteList()
      }
    },
    async handleExport () {
      // 组装请求数据，类型为 form-data
      const fd = new FormData()
      fd.append('suite_id_list', this.multipleSelections)
      fd.append('project_id', this.$store.state.projectId)
      fd.append('operation', 'export')
      // 发送请求
      const { data: res } = await ExportSuite(fd)
      // 将返回的二进制文件处理成本地文件
      const blob = new Blob([res], {
        type: 'application/vnd.ms-excel;charset=utf-8'
      })
      const objUrl = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.href = objUrl
      link.download = '测试套件下载版.xls' // 给下载后的文件命名
      link.click() // 通过主动调用 click 方法来模拟用户点击了界面上的 a 链接
      window.URL.revokeObjectURL(blob)
      // this.$refs.multipleTable.clearSelection();
    },
    handleSuite (rowData) {
      console.log('ds')
      this.$router.push({ name: 'caseList' })
      this.$store.commit('saveSuiteId', rowData.id)
    }
  }
}
</script>

<style lang="scss" scoped>
  .suite-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
  }
  .demo-form-inline {
    height: 40px;
  }
  .upload-suite {
    display: inline-block;
    margin: 0 10px;
  }
  .el-pagination {
    padding: 0px 0;
  }
</style>
