import request from '@/utils/request.js'
import { urlPath } from './apiBaseInfo'

// 查询测试套件列表
export const QuerySuiteList = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'suite',
    params: {
      ...data
    }
  })
}

// 删除测试套件
export const DelSuite = (data) => {
  return request({
    method: 'DELETE',
    url: urlPath + 'suite',
    data
  })
}

// 新增测试套件
export const AddSuite = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'suite',
    data
  })
}

// 编辑测试套件用例
export const EditSuite = (data) => {
  return request({
    method: 'PUT',
    url: urlPath + 'suite',
    data
  })
}

// 禁用/启用 测试套件用例
export const DisSuite = (data) => {
  return request({
    method: 'PATCH',
    url: urlPath + 'suite',
    data
  })
}

// 导入测试套件
export const ImportSuite = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'suite',
    data
  })
}

// 导出测试套件
export const ExportSuite = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'suite',
    responseType: 'blob',
    data
  })
}

// 依据名称查询测试套件
export const SearchSuite = (data) => {
  return request({
    method: 'GET',
    url: urlPath + 'suite',
    params: {
      ...data
    }
  })
}

// 执行测试套件
export const RunSuite = (data) => {
  return request({
    method: 'POST',
    url: urlPath + 'run/testcase',
    data
  })
}
